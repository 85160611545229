@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  --gradient: linear-gradient(
    to bottom right,
    #6259ca 0%,
    rgb(98 89 202 / 60%) 100%
  );
  --g-border-radius: 0 60px 60px 0;
  --g-box-shadow: 0 7px 12px 0 rgb(98 89 202 / 20%);
}
body,
* {
  font-family: "Popins" !important;
  margin: 0;
  padding: 0;
}
html,
body,
#root {
  height: 100%;
  scroll-behavior: smooth;
}

.App {
  background: #f6f6f6;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
a {
  text-decoration: none;
}
