section.slider-sec {
  overflow: hidden;
  font-family: 'Popins'!important;
}
section .container {
  padding-bottom: 10px;
}

.graph-image{
  width: 30em;
}


.section-content{
  color: #FFFFFF;
  font-size: 18px;
  line-height: 170%;
  margin-bottom: 30px;
  padding-right: 50px;
 font-weight:  200px;
 align-items: center;
 justify-content: center;
 margin-top: "20px";


}

.section-image{
  background-size: cover;
  background-image: linear-gradient(89.64deg, #04C8FF 0.26%, rgba(71, 136, 181, 0.786875) 48.41%, rgba(0, 50, 126, 0.56) 99.65%), url("../../assets/intro/coverimg.png ");
  margin-top: 30px;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  height: 560px;
  display: flex;
align-items: center;
justify-content: center;
}

@media screen and (max-width: 900px) {
  .section-image {
    width: 100%;
  }
  

}
