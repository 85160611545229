

.container-contact-form {
  background-image:linear-gradient(89.99deg, rgba(19, 42, 165, 0.85) 0.01%, rgba(5, 196, 253, 0.85) 99.99%),url("../../assets/contactus.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  min-height: 740px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 70px;
  position: relative;
  flex-wrap: wrap;
}



.section-contact-title {
  font-size: 30px;
  color: #ffffff;
  font-family: "Poppins";
  align-items: flex-start;
  justify-content: flex-start;
}

.address-txt {
  margin-top: 20px;
  font-size: 16px;
  font-family: "Poppins";
}

.contact-divide {
  display: flex;
  flex: 0 0 50%;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}
.contact-outer {
  flex: 0 0 48%;
  margin-right: 10px;
}
.contact-inner {
  background-color: #fafbff;
  color: #444444;
  margin-bottom: 25px;

  padding: 30px;
}
.contact-form-contact-divide {
  margin-left: auto;
  max-height: 544px;
  max-width: 600px;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.contact-form-box .MuiFormControl-root,
.contact-form-box textarea,
.contact-form-box button {
  margin: 8px !important;
  width: 100%;
}
.contact-inner p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  padding: 0;
}
.contact-form-inner {
  background-color: #fafbff;
  color: #444444;
  padding: 30px;
  border-radius: 6px;
}
.getin-txt {
  color: #263238;
  font-family: "Poppins";
  text-align: center;
  margin-top: 20px;
}


@media screen and (max-width: 900px) {
  .section-contact {
    padding: 20px 0;
  }
  .contact-details,
  .contact-divide {
    flex-direction: column;
  }
  .contact-form-inner {
    margin-bottom: 0;
    padding: 0;
  }
}

.reload-btn{
  margin-bottom: 3px;
    color: #0047B3;
    font-weight:500;
  
}



.html,body{
margin: 0px;
  }
.crmWebToEntityForm.zcwf_lblLeft {
  width:100%;
  padding: "25px";
  margin: 0 auto;
  box-sizing: border-box;
  
  }
  .crmWebToEntityForm.zcwf_lblLeft * {
  box-sizing: border-box;
  }
  .crmWebToEntityForm{text-align: left;}
  .crmWebToEntityForm * {
  direction: ltr;
  }
  .zcwf_lblLeft .zcwf_title {
  word-wrap: break-word;
  padding: 0px 6px 10px;
  font-weight: bold;
  }
  .zcwf_lblLeft .zcwf_col_fld input[type=text], .zcwf_lblLeft .zcwf_col_fld textarea {
    width: 100%;
    height: 36px;
    border: 1px solid #afbbc6 !important;
    resize: vertical;
    border-radius: 8px;
    float: left;
    padding: 0px 10px;
  }
  .zcwf_lblLeft .zcwf_col_lab {
  width: 36%;
  word-break: break-word;
  padding: 0px 6px 0px;
  margin-right: 10px;
  margin-top: 5px;
  float: left;
  min-height: 1px;
  }
  .zcwf_lblLeft .zcwf_col_fld {
  float: left;
  width: 68%;
  padding: 0px 6px 0px;
  position: relative;
  margin-top: 5px;
  border: 20px;
  }

  .zcwf_lblLeft .zcwf_privacy{padding: 6px;}
  .zcwf_lblLeft .wfrm_fld_dpNn{display: none;}
  .dIB{display: inline-block;}
  .zcwf_lblLeft .zcwf_col_fld_slt {
  width: 60%;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
 font-size: 12px;
 float: left;
 resize: vertical;
 padding: 2px 5px;
  }
  .zcwf_lblLeft .zcwf_row:after, .zcwf_lblLeft .zcwf_col_fld:after {
  content: '';
 display: table;
clear: both;
  }
  .zcwf_lblLeft .zcwf_col_help {
  float: left;
  margin-left: 7px;
  font-size: 12px;
  max-width: 35%;
 word-break: break-word;
  }
  .zcwf_lblLeft .zcwf_help_icon {
  cursor: pointer;
 width: 16px;
  height: 16px;
display: inline-block;
 background: #fff;
border: 1px solid #c0c6cc;
 color: #c1c1c1;
  text-align: center;
  font-size: 11px;
line-height: 16px;
  font-weight: bold;
border-radius: 50%;
  }
  .zcwf_lblLeft .zcwf_row {margin: 15px 0px;}
  .zcwf_lblLeft .formsubmit {
 margin-right: 5px;
cursor: pointer;
  color: var(--baseColor);
  font-size: 12px;
  }
  .zcwf_lblLeft .zcwf_privacy_txt {
  width: 90%;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Arial;
  display: inline-block;
  vertical-align: top;
  color: var(--baseColor);
  padding-top: 2px;
  margin-left: 6px;
  }
  .zcwf_lblLeft .zcwf_button {
  font-size: 14px;
  color: var(--baseColor);
  border: 5px solid;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #0047B3;
  color: #ffff;
  text-decoration:none;
  font-weight:500;
  max-width: 120px;
overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  }
  .zcwf_lblLeft .zcwf_tooltip_over{
position: relative;
  }
  .zcwf_lblLeft .zcwf_tooltip_ctn{
  position: absolute;
background: #dedede;
padding: 3px 6px;
  top: 3px;
  border-radius: 4px;word-break: break-word;
  min-width: 100px;
  max-width: 150px;
  color: var(--baseColor);
  z-index: 100;
  }
  .zcwf_lblLeft .zcwf_ckbox{
  float: left;
  }
  .zcwf_lblLeft .zcwf_file{
    width: "50%";
  box-sizing: border-box;
  float: left;
  }
  .clearB:after{
  content:'';
  display: block;
  clip: both;
  }
    .zcwf_lblLeft .zcwf_col_help {
    width: "40%";
  }
  .contact-card-container {
    border-radius: 10px 10px 10px 10px;
    height: 560px;
    border-top: none;
    width: 50%;
    margin-bottom: 108px;
    position: absolute;
    right: 30%;
    top: 62%;
    bottom: 26%;
    transform: translate(50%, -68%);
    margin-left: auto;
    margin-right: auto;
    display: wrap;
    box-shadow: rgb(71 70 70 / 24%) 0px 3px 8px;
    background-color: #fff;
  }


 