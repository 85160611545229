section.infodetails-sec {
    padding: 10px 0;
    margin-top: 70px;
    justify-content: center;
    align-items: center;

}

.MuiGrid-root.MuiGrid-grid-sm-3{
    flex-grow: 0;
    max-width: 20%;
    flex-basis: 25%;
    
}


.MuiGrid-container-info {
    width: 103%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    gap: 30px;
    margin-top: 30px;
    
}

.section-caption-info h2{
color: #00327E;
font-size: 45px;
font-style: 'Popins';
font-weight: 800;
align-items: center;
margin-left: 80px;
justify-content: center;


}



.icon-outer-box img{
    height: 70px;
    width: 70px;
    justify-content: center;
}



.MuiGrid-container.section-infodetails {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    
}
   

@media screen and (max-width:900px){ 
    section.infodetails-sec .section-infodetails {
        flex-direction: row;

  
    }


  
   
}

