* {
  font-family: "Poppins"!important;
}

.pricing-background {
  background-image:linear-gradient(89.99deg, rgba(19, 42, 165, 0.85) 0.01%, rgba(5, 196, 253, 0.85) 99.99%)
  ,url("../../assets/Scalable_pricing.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 70px;
  position: relative;
  flex-wrap: wrap;
}
.pricing-title {
  font-size: 40px;
  color: #fff;
  margin-top: 40px;
}
.pricing-description{
  font-size: 22px;
  color: #fff;
  font-weight: 400;
  margin-top: 20px;
}

.pric-nav-links{
  color: #fff;
  font-size: 18px;
  line-height: 248%;
  text-decoration: none;
}
.pric.nav-links li{
  color: #fff;
  font-size: 16px;
  line-height: 100%;
  text-decoration: none;

display: flex;
}
@media screen and (max-width: 900px) {
  .pricing-background {
    flex-direction: column;
    height: auto;
  }
 
}
