section.section-overview {
    text-align: center;
    margin-top: 70px;
}
section.section-overview .section-overview-title {
    color: #00327E;
    text-align: left;
    font-family: 'Popins';
    margin-top: 30px;
    font-size: 45px;
    

}


section.section-overview .overview-desc{
    color: #00327E;
    font-size: 16px;
    line-height: 180%;
    margin: auto;
    text-align: left;

    margin-top: 30px;
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
}

.overview-img{
height: 400px;
width: 400px;

}

@media screen and (max-width:900px){ 
    section.section-overview{
        padding: 20px 0;
    }
    section.section-overview .section-desc {
        max-width: 100%;
    }
}