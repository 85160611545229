.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding: 10px 0;
  position: relative;
}
.navbar-logo {
  flex: 0 0 30%;
}
.navbar-logo img {
  margin: 0 50px;
  height: auto;
  width: 92px;
}
.navbar-menus {
  flex: 0 0 60%;
  position: relative;
}
.navbar-menus .nav-links {
  align-items: center;
  display: flex;
  list-style: none;
}
.demo-link {
  display: flex;
  flex: 0 0 13%;
}
.navbar-menus .nav-links li {
  margin-right: 30px;
}
.navbar-menus .nav-links li a{
  color: #263238;
  display: block;
  font-size: 16px;
  line-height: 100%;
  text-decoration: none;

}

.navbar-menus .nav-links li a:hover{
  color: #0047B3;
  display: block;
  font-size: 16px;
  line-height: 100%;
  text-decoration:none;
  font-weight:500;
  border-bottom: 2px solid ;
}

.navbar-menus .nav-links li.active {
  color:#0047B3;
  display: block;
  font-size: 16px;
  line-height: 100%;
  text-decoration:none;
  font-weight:500;
  border-bottom: 2px solid #0047B3 ;
}



.demo-link a {
  background-color: #0047B3;
  border-radius: 45px;
  color: #fff;
  font-size: 15px;
  padding: 8px 10px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all ease-in-out 0.3s;
  width: 100%;
}
.menu-icon {
  background-color: #ddd;
  cursor: pointer;
  display: none;
  float: right;
  padding: 9px 10px;
  position: relative;
  right: 15px;
}
.mobile {
  display: none;
}
.navbar-menus .nav-links li:last-child a{
  background-color: #0047B3;
  border-radius: 5px;
  color: #fff;
  flex: 0 0 15%;
  font-size: 15px;
  padding: 8px 10px;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  width: 100%;
}

.navbar-menus.nav-links.demo-button {
  background-color: #0092ca;
  color: #fff;
  margin-right: 10px;
}

@media screen and (min-width: 900px) {
  .navbar-menus .nav-links li:last-child {
    float: right;
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 900px) {
  .navbar {
    display: block;
  }
  .navbar-logo img {
    margin-left: 15px;
  }
  .navbar-menus {
    background-color: #ddd;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    display: none;
    height: auto;
    max-height: 300px;
    overflow: hidden;
    position: absolute;
    top: 72px;
    width: 100%;
    z-index: 9;
  }
  .menu-icon {
    display: block;
  }
  .navbar-mobile.active {
    display: block;
  }
  .nav-links {
    align-items: flex-start !important;
    flex-direction: column;
    width: 100%;
  }
  .navbar-menus .nav-links li {
    border: 1px solid #fff;
    margin: 0;
    width: 100%;
  }
  .navbar-menus .nav-links li a {
    display: block;
    padding: 15px 15px;
    position: relative;
  }
  .navbar-menus .nav-links li:last-child {
    border: none;
  }
  .navbar-menus .nav-links li:last-child a {
    border-radius: 0;
    padding: 15px 15px;
  }
  footer .container {
    flex-direction: column;
  }
  .footer-info,
  .footer-links,
  .social-links {
    margin-bottom: 15px;
    padding: 0;
  }
  .copyright {
    margin: 0 !important;
  }

}
