section.section-client {
    align-items: center;
        display: flex;
        margin-top: 70px;
        justify-content: center;
        flex-wrap: wrap;

}

.multiplans-section {
        background-image: url("../../assets/clients/plans.png");
        width: 816px;
        height: 196px;
        background-repeat: no-repeat;
        background-size: 816px 196px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 29px;
        flex-wrap: wrap;
       
    }
@media screen and (max-width:900px) {
    section.section-client {
        padding: 20px 0;
    }
    
   
}
