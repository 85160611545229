.section-service {
    background-color: #BDBDBD;
    flex-wrap: wrap;
    margin-top: 70px; 
    justify-content: center;
    font-family: 'Popins';
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 80px;
    background-image: linear-gradient(89.89deg, #2663CA 1.15%, rgba(5, 199, 211, 0.43) 99.06%);

}

.section-service-title {
    color: #FFF7F7;
    text-align: center;
    font-family: 'Popins';
    font-weight: 700px;
    font-size: 48px;
    align-items: center;
    justify-items: center;
    padding: "0px 80px";
    display: "flex";
    flex-direction: column;
    
}



.service-desc{
    color: #FFFFFF;
    font-size: 18px;
    line-height: 172%;
    font-weight: 400px;
    text-align: left;
    align-items: left;
    font-family: 'popins';
    padding: "0px 80px";
    display: "flex";
    flex-direction: column;
    justify-items: center;

    
   
}

.service-img{
height: 540px;
width: 615px;
}
.section-usecase{
    align-items: center;
    justify-content: center;
    
}

@media screen and (max-width:900px){ 
    section.section-service{
        padding: 20px 0;
    }
    
}