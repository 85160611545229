footer.frontend-footer {
  background-color: #FFFFFF;
  padding: 60px 0 0;
  padding: 10px 0;
  border-radius: 10px 10px 10px 10px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  margin-top: 70px;
  
}
footer.frontend-footer .container {
  display: flex;
  margin: auto;
  max-width: 1186px;
  overflow: hidden;
  padding: 0 15px;
}
footer.frontend-footer .footer-info .footer-logo {
  margin-bottom: 5px;
  width: 120px;
}
footer.frontend-footer.footer-info.footer-logo.footer-txt {
  font-size: 12px !important;
  margin-bottom: 20px;
  font-style: normal;
  font-family: "Popins";
  color: #000000;
}

.footer-info{
font-size: 12px;
flex: 0 0 33.33%;
padding: 0 40px 0 0;


}
.footer-links,
.social-links {
  flex: 0 0 33.33%;
  padding: 0 40px 0 0;
  color: #333333
}
.social-links {
  padding: 0 !important;
}
footer.frontend-footer h6 {
  font-size: 16px;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 142px;

}
.footer-links .nav-links {
  align-items: center;
  justify-content: center;
}
.footer-links .nav-links li {
  margin-bottom: 7px;
  align-items: center;
  list-style-type: none;
}
.footer-links .nav-links li a {
  color: #0b1320;
  display: block;
  line-height: 20px;
  justify-content: center;
  text-decoration: none;
  margin-left: 14px;
  list-style-type: none;
}
.copyright {
  border-top: 1px dashed #828282;
  margin: 50px 0 0, 0;
  padding: 15px 0;
  text-align: center;
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;
  align-items: center;
}

.copyright p {
  font-size: 13px;
}
.frontend-footer.copyright {
  height: 6px;
  width: 6px;
}
.india-flag {
  justify-content: flex-end;
  display: flex;
  margin-top: -40px;
  margin-right: 60px;
}

.social-link {
  display: flex;
  flex-wrap: wrap;
  list-style: none;

}
.social-link li {
  align-items: center;
  background-color: #fff;
  color: #0b1320;
  display: flex;
  font-size: 0.875rem;
  height: 40px;
  justify-content: center;
  line-height: 15px;
  margin: 0 10px 0 0;
  text-align: center;
  transition: 250ms, border 250ms;
  width: 40px;
}
.social-link li a {
  color: #0b1320;
  font-size: 18px;
}
.copyright .MuiSvgIcon-root {
  margin: -7px auto;
}
