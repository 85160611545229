section.account-login {
    background-image: linear-gradient(90deg, rgba(19, 42, 165, 0.85) 0%, rgba(5, 196, 253, 0.5355) 100%)
    ,url("../../assets/loginb.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding:198px 165px;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);;
    

}


section.account-login .container {
    margin: auto;
    padding: 0 15px;
    

}
.backg-section{
    justify-content: center;
    align-items: center;
    

}
.signin-btn{

    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
}

.account-login-form-container {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    margin: auto;
    padding: 77px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.account-login-form-container .section-title {
    font-size: 20px;
    font-weight: 500;
     background: #FFFFFF;
    align-items: center;
}
 .section-wlm-title{
    font-size: 38px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    align-items: center;

}
.account-login-form-container hr {
    margin-bottom: 20px;
    margin-top: 20px;
}
.account-login-form-container .form-box .MuiFormControl-root, .account-login-form-container .form-box p.formot-password,.account-login-form-container .form-box button,.account-login-form-container .form-box p.account-link {
    margin: 0 !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    justify-content: center;
    align-items: center;
    display: flex;
}
p.formot-password {
    text-align: right;
}
p.formot-password a {
    color:#333;
    text-decoration: none;
}
p.account-link a {
    text-decoration: none;
  color: #00327E;
 font-weight: 500;
 

}


.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}

.alert {
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 15px;
}
.account-link{
    align-items: center;
 display: flex;
 justify-content: center;
}

@media screen and (max-width:900px){ 
    .account-login-form-container {
        width: 100%;
    }
    section.account-login {
        padding: 20px 0;
    }
}
