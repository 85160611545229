.demo-background {
  background-image: linear-gradient(
      90deg,
      rgba(19, 42, 165, 0.85) 0%,
      rgba(5, 196, 253, 0.5355) 100%
    ),
    url("../../assets/productSupport.png");

  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 750px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.input-container {
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding-bottom: 15px;
  padding-top: 15px;
}

.demo-card-container {
  border-radius: 10px 10px 10px 10px;
  min-height: 800px;
  border-top: none;
  width: 65%;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -68%);
  margin-left: auto;
  margin-right: auto;
  display: wrap;
  box-shadow: rgba(71, 70, 70, 0.24) 0px 3px 8px;
  background-color: #fff;
}
.error {
  color: #d32f2f !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  line-height: 1.66;
  margin-bottom: 0;
  margin-left: 20px;
  margin-right: 14px;
  text-align: left;
}

.support-outer-box {
  padding: 32px;
  position: relative;
  text-align: center;
  transition: all ease-in-out 0.2s;
  align-items: center;
}

.section-support {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 2px;
  align-items: "flex-start";
  padding: 40px, 0px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 900px) {
  section.products-sec .section-products {
    flex-direction: column;
  }
}

html,
body {
  margin: 0px;
}
.crmWebToEntityForm.zcwf_lblLeft {
  width: 100%;
  padding: 25px;
  margin: 0 auto;
  box-sizing: border-box;
}
.crmWebToEntityForm.zcwf_lblLeft * {
  box-sizing: border-box;
}
.crmWebToEntityForm {
  text-align: left;
}
.crmWebToEntityForm * {
  direction: ltr;
}
.zcwf_lblLeft .zcwf_title {
  word-wrap: break-word;
  padding: 0px 6px 10px;
  font-weight: bold;
}
.zcwf_lblLeft .zcwf_col_fld input[type="text"],
.zcwf_lblLeft .zcwf_col_fld textarea {
  width: 60%;
  border: 1px solid #c0c6cc !important;
  resize: vertical;
  border-radius: 8px;
  float: left;
  outline: none;
  padding: 0px 10px;
}
.zcwf_lblLeft .zcwf_col_lab {
  width: 30%;
  word-break: break-word;
  padding: 0px 6px 0px;
  margin-right: 10px;
  margin-top: 5px;
  float: left;
  min-height: 1px;
}
.zcwf_lblLeft .zcwf_col_fld {
  float: left;
  width: 68%;
  padding: 0px 6px 0px;
  position: relative;
  margin-top: 5px;
  background-color: "#A9C3FF";
  border: "1px solid #A9C3FF";
  border-radius: " 4px";
  font-variant: normal;
}
.zcwf_lblLeft .zcwf_privacy {
  padding: 6px;
}
.zcwf_lblLeft .wfrm_fld_dpNn {
  display: none;
}
.dIB {
  display: inline-block;
}
.zcwf_lblLeft .zcwf_col_fld_slt {
  width: 60%;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  float: left;
  resize: vertical;
  padding: 2px 5px;
}
.zcwf_lblLeft .zcwf_row:after,
.zcwf_lblLeft .zcwf_col_fld:after {
  content: "";
  display: table;
  clear: both;
}
.zcwf_lblLeft .zcwf_col_help {
  float: left;
  margin-left: 7px;
  font-size: 14px;
  max-width: 35%;
  word-break: break-word;
}
.zcwf_lblLeft .zcwf_help_icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: inline-block;
  background: #fff;
  border: 1px solid #c0c6cc;
  color: #c1c1c1;
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
  border-radius: 50%;
}
.zcwf_lblLeft .zcwf_row {
  margin: 15px 0px;
}
.zcwf_lblLeft .formsubmit {
  cursor: pointer;
  color: var(--baseColor);
  font-size: 14px;
}
.zcwf_lblLeft .zcwf_privacy_txt {
  width: 90%;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-family: Arial;
  display: inline-block;
  vertical-align: top;
  color: var(--baseColor);
  padding-top: 2px;
  margin-left: 6px;
}
.zcwf_lblLeft .zcwf_button {
  font-size: 14px;
  color: var(--baseColor);
  border: 5px solid;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #0047b3;
  color: #ffff;
  text-decoration: none;
  font-weight: 500;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.zcwf_lblLeft .zcwf_tooltip_over {
  position: relative;
}
.zcwf_lblLeft .zcwf_tooltip_ctn {
  position: absolute;
  background: #dedede;
  padding: 3px 6px;
  top: 3px;
  border-radius: 4px;
  word-break: break-word;
  min-width: 100px;
  max-width: 150px;
  color: var(--baseColor);
  z-index: 100;
}
.zcwf_lblLeft .zcwf_ckbox {
  float: left;
}
.zcwf_lblLeft .zcwf_file {
  width: 55%;
  box-sizing: border-box;
  float: left;
}
.clearB:after {
  content: "";
  display: block;
  clear: both;
}
