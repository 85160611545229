.cta-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 70px;
    flex-wrap: wrap;

    
}

.section-cta-title {
    color: hsl(216, 100%, 25%);
    font-size: 45px;
    font-family: 'Popins';
}


.cta-desc{
    color: #00327E;

    line-height: 170%;
    font-size: "20px";
    color: "#00327EB2";
    text-align: left;
    font-weight: 400;
    /* font-family: Popins; */
}

.cta-img{
height: 464px;
width: 472px;

}
.cta-imgs{
    height: 458px;
width: 418px;
}

.plans-section {
    background-image: url("../../assets/clients/plans.png");
    width: 890px;
    height: 200px;
    margin-bottom: 70px;
    background-repeat: no-repeat;
    background-size: 890px 200px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 29px;
   
}

.cf:before, .cf:after{
    content:"";
    display:table;
}

.cf:after{
    clear:both;
}

.cf{
    zoom:1;
}    

.search-wrapper {
    width: 450px;
    border-radius: 40px;
  background: white;
    box-shadow: 0 4px 20px -2px #e9e9e9;
  border: 1px solid #0047B3
}

.plans-outer-box{
    justify-content: center;

}

.search-wrapper input {
     padding-left: 20px;
     width: 290px;
     height: 20px;
     padding: 10px 5px;
     float: left;
     font-family: 'popins';
   border: 0;
     border-top-style: none;
     background: #fff;
     border-radius: 40px;
    border-top-style: none;
     overflow: hidden;
     overflow-y: hidden;
    overflow-y: hidden;
    margin-left: 16px;
    }

.search-wrapper input:focus {
    outline: 0;
    background: #fff;
}

.search-wrapper input::-webkit-input-placeholder {
   color: 
   #263238;
   font-weight: 100px;
   font-style: normal;
  padding-left: 20px;
}

.search-wrapper input:-moz-placeholder {
    
    color: #263238;
    font-weight: normal;
    font-style: normal;
}

.search-wrapper input:-ms-input-placeholder {
    color: #263238;
    font-weight: normal;
    font-style: normal;
  border-style: none;
}   

.search-wrapper button {
    overflow: visible;
    position: relative;
    float: right;
    border: 0;
    padding: 0;
    cursor: pointer;
    height: 40px;
    width: 110px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    background: #0047B3;
    border-radius: 40px;
    text-shadow: 0 -1px 0 rgba(0, 0 ,0, .3);
}  

.search-wrapper button:hover{    
    background: #407BFF;
}  

.search-wrapper button:active,
.search-wrapper button:focus{  
    background: #0047B3;
    outline: 0;  
}

.search-wrapper button:focus:before,
.search-wrapper button:active:before{
        border-right-color: #c42f2f;
}     

.search-wrapper button::-moz-focus-inner { 
    border: 0;
    padding: 0;
}   

@media screen and (max-width:900px){ 
    section.section-cta{
        padding: 20px 0;
    }
    section.section-cta .section-desc {
        max-width: 100%;
    }
}