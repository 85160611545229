@media screen and (width: 1600px){
   section.products-sec .section-products
   {
     width: 89%;}.section-products .flex-center{
       width: 89%;}}

section.products-sec {

  padding: 10px 0;
  margin-top: 70px;
}
section.products-sec .container {
  margin: auto;
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-top: 20px;
}

.product-section-caption {
  color: #00327e;
  font-size: 45px;
  font-style: "Popins";
  font-weight: 200;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
}
.nav-link-li{
   display: flex;
  flex-wrap: wrap;
   flex-direction:row;
   font-size: "15px";
    line-height: "27px";/* card details */
}
.text-details{ width: 90%;}
.dot{
  background-color: #00327E;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  padding: 0px;
  margin: 8px;

}

.product {
  background-image: url("../../assets/products/features.png");
  width: 340px;
  height: 180px;
  margin: 10px;
  background-size: 340px 180px;
  cursor: pointer;
}

section.products-sec .section-products {
  align-items: left;
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  margin-left: auto;
  margin-right: auto;

   width: 90%;
}

section.products-sec .section-products .product .product-outer-box {
  padding: 32px;
  position: relative;
  text-align: center;
  transition: all ease-in-out 0.2s;
}
.product-outer-box img {
  width: 100%;
}
.product-outer-box {
  position: relative;
  text-align: center;
  transition: all ease-in-out 0.2s;
}
.product.product-icon {
  position: "absolute";
  height: "70px";
  width: "70px";
  left: "50px";
  top: "50px";
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  section.products-sec .section-products {
    flex-direction: column;
    border-radius: 10px;
  }

  h1 {
    font-size: 20px;
    text-align: left;
    text-transform: capitalize;
  }
  .service-box {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    perspective: 1000px;
    -webkit-perspective: 1000px;
  }
  .service-icon {
    width: 100%;
    height: 220px;
    padding: 20px;
    text-align: center;
    transition: all 0.5s ease;
  }

  .service-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 220px;
    padding: 20px;
    text-align: center;
    transition: all 0.5s ease;
    background-color: #474747;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform: translateY(110px) rotateX(-90deg);
    -moz-transform: translateY(110px) rotateX(-90deg);
    -ms-transform: translateY(110px) rotateX(-90deg);
    -o-transform: translateY(110px) rotateX(-90deg);
    transform: translateY(110px) rotateX(-90deg);
  }
  .service-box .service-icon .front-content {
    position: relative;
    top: 80px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .service-box .service-icon .front-content i {
    font-size: 28px;
    color: #fff;
    font-weight: normal;
  }

  .service-box .service-icon .front-content h3 {
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  .service-box .service-content h3 {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .service-box .service-content p {
    font-size: 13px;
    color: #b1b1b1;
    margin: 0;
  }
  .yellow {
    background-color: #ffc000;
  }
  .orange {
    background-color: #fc7f0c;
  }
  .red {
    background-color: #e84b3a;
  }
  .grey {
    background-color: #474747;
  }
  .service-box:hover .service-icon {
    opacity: 0;
    -webkit-transform: translateY(-110px) rotateX(90deg);
    -moz-transform: translateY(-110px) rotateX(90deg);
    -ms-transform: translateY(-110px) rotateX(90deg);
    -o-transform: translateY(-110px) rotateX(90deg);
    transform: translateY(-110px) rotateX(90deg);
  }
  .service-box:hover .service-content {
    opacity: 1;
    -webkit-transform: rotateX(0);
    -moz-transform: rotateX(0);
    -ms-transform: rotateX(0);
    -o-transform: rotateX(0);
    transform: rotateX(0);
  }

  @import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;900&display=swap");

  body {
    background: #f2f2f2;
  }

  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 400px;
    width: 600px;
    background: #f2f2f2;
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 0 20px 8px #d0d0d0;
  }

  .content {
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    text-align: justify;
    color: black;
    padding: 40px;
    font-family: "Merriweather", serif;
  }

  h1 {
    font-weight: 900;
    text-align: center;
  }

  h3 {
    font-weight: 300;
  }

  .flap {
    width: 100%;
    height: 100%;
  }

  .flap::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 50%;
    background: url("https://pbs.twimg.com/profile_images/1347260174176710658/2GfSZ1i__400x400.jpg")
      white;
    background-position: 100px;
    background-repeat: no-repeat;
    transition: 1s;
  }

  .flap::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 50%;
    right: 0;
    background: url("https://pbs.twimg.com/profile_images/1347260174176710658/2GfSZ1i__400x400.jpg")
      white;
    background-position: -200px;
    background-repeat: no-repeat;
    transition: 1s;
  }

  .container:hover .flap::after {
    transform: translatex(300px);
  }

  .container:hover .flap::before {
    transform: translatex(-300px);
  }

  .nav-links{
    color: #00327E;
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;
  
  }
  .nav-links li{
    color: #00327E;
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;
  
  display: flex;
  }
  .cardbox{

      position:absolute;
      border: "20px";
      background-color: #b1b1b1;
      background-image: url('../../assets/featuers/cardbox.png');
      /* // imgUrl:{Cardbox,
      // backgroundImage: "url('../../assets/featuers/cardbox.png')",
      /* src: { Cardbox }, */
      border-radius: "20px";
      top: "50%";
      left: "50%";
      align-items: "center";
      transform: "translate(-50%, -50%)";
      width: "400px";
      font-size: "12px";
      position: 4,
    
  }

}
